.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 28%;
   
}

.popup-button {
    border: none;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
    height: 40px;
    background-color: #f27930;
    color: white;
}

.ps-6{
    padding-left: 80px;
}

.hero-section{
    height: 100%;
    display: flex;
    align-items: center;
}

.hero-outer{
background-image: url('/public/bg-shape.jpg');
height: 100%;
width: 100%;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}

.content-heading {
    font-size:48px;
    line-height: 1.2;
    font-weight: normal;
}

.content-text1{
    color: #252931 !important;
    margin-top: 60px !important;
    font-weight: normal;
    font-size: 20px;
}

.content-text{
    color: #252931 !important;
    font-weight: normal;
    font-size: 20px;
}

.content-btn {
    padding: 15px 40px;
}
a{
    text-decoration: none;
    color: black;
}

video { 
    border-radius: 10px; 
    overflow: hidden; 
}


/* Media queries for responsiveness */
@media (max-width: 768px) {
    .popup-content {
        max-width: 60%;
    }
}

@media (max-width: 768px) {
    .popup-content {
        max-width: 95%;
    }

    .hero-outer{
        background-image: none;
        position: relative;
    }

    .hero-outer::after{
        content: '';
        position: absolute;
        top: 0;
        height: 50%;
        width: 100%;
        background-color: #ebf5fd;
        z-index: -1;
    }

    .hero-row{
        flex-direction: column-reverse;
        position: relative;
        padding-bottom: 70px;
    }

    .hero-btn{
        text-align: center !important;
        margin: 0 auto;
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: fit-content;
    }

    .content-heading{
        font-size: 32px;
    }

    .content-text{
        font-size: 16px;
    }

    .hero-section{
        min-height: inherit !important;
    }

    .hero-video{
        height: 460px;
    }

    .video-outer{
        width: fit-content;
        height: fit-content;
        border-radius: 20px;
        overflow: hidden;
        margin: 0 auto;
    }
}

