.faq p{
text-align: left;
}

.faq h3{
    font-size: 2rem;
    }


.faq-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}


.faq-content {
    max-width: 100%;
}

.faq-color h6{
    padding-bottom: 0 !important;
}

.faq-content h6 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #143a72;
    position: relative;
    padding-bottom: 8px;
}
.faq-content.bottom-border h6 {
    position: relative;
    padding-bottom: 8px;
}

.faq-content.bottom-border h6::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 32px;
    height: 4px;
    background: linear-gradient(to left,#f27830, #113874);
    border-radius: 10px;
}

.faq-heading h5 {
    font-size: 26px;
    font-weight: bold;
}

.contact-img{
    object-fit: cover;
}

/* accourdion */
.accordion-body, .accordion-button{
    padding-left: 0;
}

.accordion-button:not(.collapsed){
    background-color: transparent;
}

.accordion-button:focus{
    box-shadow: none;
}

.accordion-item{
    margin: 25px 0;
}

.accordion-button{
    font-size: 16px;
    font-weight: bold;
}

.accordion > .accordion-item:last-of-type {
border-bottom: 1px solid #dee2e6;
}


.disclamer-section p {
    opacity: 0.5;
    font-size: 13px;
    margin-top: 30px !important;
}


.section-bg{
    background-image: url('/public/bg-shape.jpg');
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0;
    padding-bottom: 15px;
}

.mini-section {
    display: flex;
    gap: 60px;
    flex-wrap: wrap;
}

.mini-section .content {
    background-color: #ffffff;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: auto;
    border-radius: 20px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mini-section .content p {
    font-size: 12px;
    font-weight: normal;
    margin: 0;
    padding-top: 10px;
    line-height: 1.4;
}

.mini-section .content h6 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    text-transform: capitalize;
    color: #1b424c;
    font-size: 16px;
    padding-top: 10px;
}

.mini-section .content i {
    font-size: 22px;
    background: -webkit-linear-gradient(360deg, #6da1fc, #4cd9fe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mini-section-heading {
    text-align: start;
    margin-bottom: 35px;
}

.quest-heading-small{
text-align: center;
margin-bottom: 20px;
}
.quest-heading-small h6{
font-size: 14px;
padding: 11px 20px;
background-color: #f7f8f9;
display: inline-block;
border-radius: 30px;
color: #6d7585;
}

.quest-heading{
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

.quest-heading h2{
    font-size: 32px;
    color: #143a72;
    position: relative;
    padding-bottom: 20px;
}


.quest-heading.bottom-border h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 32px;
    height: 4px;
    background: linear-gradient(to left,#f27830, #113874);
    border-radius: 10px;
}

@media (max-width: 768px) {

    .mini-section-bg{
        background-image: none;
        padding-top: 0;
    }

    .mini-section{
        flex-direction: row;
        flex-wrap: nowrap !important;
        overflow-x: auto;
        gap: 20px !important;
        padding: 10px 0;
    }

    .mini-section-bg > div{
        position: relative;
        width: 100%;
        height: 100%;
    }

    .mini-section::after{
        content: '';
        position: absolute;
        z-index: -1;
        background-image: url('/public/bg-shape.jpg');
        bottom: 0;
        left: 0;
    height: 75%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    }

    .mini-section .content{
        flex: 1 0 70% !important;
        width: 100%;
        height: 150px;
    }
    

    .contact-img img{
        max-width: 100%;
    }

    .faq-content h6{
        font-size: 16px;
        font-weight: 700;
    }

    .faq-content p{
        font-size: 16px;
    }

    .faq-color h6{
        color: #505662;
    }

    .contact-img{
        display: none;
    }


}